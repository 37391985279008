<template>
    <div class="confirm">
        <form @submit.prevent="submitForm">
            <div class="title">选购车型信息</div>
            <div class="car-info-container">
                <div class="car-info">{{orderInfo['carVals']}}<span>车系/车型:</span></div>
                <div class="car-info" v-if="orderInfo['colorId'] != 0">{{orderInfo['colorVal']}}<span>预定颜色:</span></div>
                <div class="car-info">¥{{orderInfo['guidePrice']}}<span>车辆指导价:</span></div>
                <label v-if="series.ACTIVITYTYPE == 1">
                    <div class="car-info">{{series['SUBPLICY']}}<span>补贴点数:</span></div>
                    <div class="car-info">¥{{series['sub_price']}}<span>补贴金额:</span></div>
                </label>
                <label v-else-if="series.ACTIVITYTYPE != 5">
                    <div class="car-info">¥{{orderInfo['carPrice']}}<span>员工优惠价:</span></div>
                </label>
                <label  v-if="downPrice != 0">
                    <div class="car-info">¥{{downPrice}}<span>购车优惠券:</span></div>
                    <div class="car-info">¥{{showPrice}}<span>最终优惠价:</span></div>
                </label>
                <div class="car-info" v-if="showLoan">{{loanInfo}}<span>贷款方案:</span></div>
            </div>
            <div class="title">员工基本信息 <span>注：为加快审核速度，请精准填写信息</span></div>
            <div class="order-info-container">
                <div class="cell-container">
                    <van-field label="姓名:" placeholder="请输入姓名" v-model="dataForm.name"/>
                    <!-- <img src="/images/userinf_username.png"> -->
					<i class="confirm-icon userinf_username"></i>
                </div>
                <div class="cell-container">
                    <van-field label="身份证:" placeholder="3XXXXXXXXXX" maxlength="18" v-model="dataForm.cardID"/>
                    <!-- <img src="/images/userinf_idcard.png"> -->
					<i class="confirm-icon userinf_idcard"></i>
                </div>
                <div class="cell-container">
                    <van-field label="工号:" error-message="若无工号请勿填写" v-model="dataForm.code"/>
                    <!-- <img src="/images/userinf_workno.png"> -->
					<i class="confirm-icon userinf_workno"></i>
                </div>
                <div class="cell-container">
                    <van-field label="工作邮箱:" error-message="若无工作邮箱请勿填写" v-model="dataForm.mail"/>
                    <!-- <img src="/images/userinf_mail.png"> -->
					<i class="confirm-icon userinf_mail"></i>
                </div>
                <div class="cell-container">
                    <van-field label="手机号:" value="" placeholder="请输入手机号" type="number" maxlength="11"  v-model="dataForm.mobile"/>
                    <img src="/images/userinf_phone.png">
					<i class="confirm-icon userinf_phone"></i>
                </div>
                <div class="cell-container" v-if="!dataForm.hasPhone">
                    <van-field label="验证码:" value="" placeholder="请输入验证码"  type="number" maxlength="6"  v-model="dataForm.token">
                        <van-button slot="button" size="small" type="info"  @click="getMobileToken()">{{tokenBtnMsg}}</van-button>
                    </van-field>
                    <img src="/images/lab_c.png">
                </div>
            </div>
            <div class="class-upload-container" v-if="dataForm.imgCode.length > 0">
                <div class="upload-container">
                    <div class="upload-title">上传证明</div>
                    <div class="upload-box">
                        <div class="upload" :class="'upload' + code.code" v-for="(code, index) in dataForm.imgCode" :key="index">
                            <van-uploader :after-read="afterRead" :before-delete="delImg" :name="index" :max-count="1" :file-list="fileList[index]">
                            </van-uploader>
                            <p>{{code.name}}</p>
                        </div>
                    </div>
                    <p class="bottom-prompt">请确保身份证信息清晰并在有效期之内</p>
                </div>
            </div>
            <div class="bottom-submit">
                <van-button type="info" size="large" round native-type="submit" @click="saveBTSubmit()" v-if="series.ACTIVITYTYPE == 1">保存并提交订单</van-button>
                <van-button type="info" size="large" round native-type="submit" @click="savePortSubmit()" v-else-if="series.ACTIVITYTYPE == 5">保存并提交订单</van-button>
                <van-button type="info" size="large" round native-type="submit" @click="saveSubmit()" v-else>保存并提交订单</van-button>
            </div>
        </form>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, Field, Uploader, Dialog} from 'vant';

    vue.use(Button).use(Field).use(Uploader).use(Dialog);

    export default {
        name: 'order-confirm',
        data() {
            return {
                idCard : [],
                idCardBack : [],
                treeWelfare : [],
                other : [],
                dataForm : {
                    'name' : '',
                    'cardID' : '',
                    'code' : '',
                    'mail' : '',
                    'mobile' : '',
                    'token' : '',
                    'hasPhone' : 0,
                    'imgCode' : [],
                    'hasPic' : 0,
                },
                tokenBtnMsg : '获取验证码',
                deadline : 60,
                btnDisable : false,
                orderInfo : {
                    'carVals' : '- - -',
                    'carPrice' : '-',
                    'guidePrice' : '-',
                    'colorVal' : '-'
                },
                fileList : [],
                loadShow: false,
                downPrice : 0,
                showPrice : 0,
                couponInfo : [],
                series : [],
                showLoan : false,
                loanInfo : '',
                loanGoodsId : 0,
                loanPrice : 0,
                markId : 0
            }
        },
        created: function(){
            this.loadShow = true;
            this.orderInfo  = this.$store.state.order;
            if(this.orderInfo['provinceId'] == 0 || this.orderInfo['cityId'] == 0 || this.orderInfo['typeId'] == 0|| this.orderInfo['markId'] == 0){
                //无省份 城市 颜色 分类和品牌id 跳转到首页
                window.location.href = '/index';
            }
            this.markId = this.orderInfo['markId'];
            if(this.orderInfo['showSsss'] == 1 && this.orderInfo['ssssId'] == 0){
                //有ssss店并且4s店id为0 跳转到首页
                window.location.href = '/index';
            }
            //获取车展示信息
            this.$post('getColoInfo', {
                provinceId: this.orderInfo['provinceId'],
                cityId: this.orderInfo['cityId'],
                custId: 1058,
                markId: this.orderInfo['markId'],
                typeId: this.orderInfo['typeId']
            }).then((res) => {
                if(res.code == 200){
                    this.series = res.data;
                }
            });

            //获取用户信息
            let data = {
                'typeId' : this.$store.state.order.typeId,
            };
            this.$post('getUserImg', data).then((res) => {
                if(res.code == 200){
                    let data = res.data;
                    this.dataForm['name'] = '';
                    this.dataForm['cardID'] = '';
                    this.dataForm['code'] = '';
                    this.dataForm['mail'] = '';
                    this.dataForm['mobile'] = '';
                    this.dataForm['imgCode'] = data['imgCode'];

                    this.loadShow = false;
                }else{
                    Dialog.alert({
                        message: res.msg,
                    }).then(() => {
                        // on close 跳转到首页
                        window.location.href = '/index';
                    });
                }
            })
        },
        mounted: function(){
            if(this.markId == 1054){
                let loanGoodsInfo = this.$store.state.loan.bankGoodsInfo;
                if(loanGoodsInfo.ID != undefined && loanGoodsInfo.ID != 0){
                    this.loanInfo = '¥'+loanGoodsInfo['PRICE']+'    '+loanGoodsInfo['YEAR'] + '年('+loanGoodsInfo['NUMBER']+'期)';
                    this.showLoan = true;
                    this.loanGoodsId = loanGoodsInfo['ID'];
                    this.loanPrice = loanGoodsInfo['PRICE'];
                    this.$forceUpdate();
                }
            }
        },
        methods: {
            /**
             * 上传图片
             */
            afterRead(event, e) {
                //直接上传图片
                this.loadShow = true;
                let index = e.name;
                let imgCode = this.dataForm['imgCode'];
                let fileInfo = Object.assign({}, imgCode[index]);
                this.$uploadFile('/orderUploadFile', fileInfo, event.file).then((res)=>{
                    this.loadShow = false; if(res.code == 200){
                        let data = res.data;
                        let fileItem = {
                            url : res['baseUrl'] + data['path'] + '/' + data['fileName'],
                            name : '123',
                        };
                        let fileList = this.fileList;
                        if(typeof fileList[index] == 'undefined'){
                            fileList[index] = [];
                        }
                        fileList[index].push(fileItem);
                        this.fileList = fileList;
                        //只支持单个上传
                        imgCode[index]['id'] = data['id'];
                        this.dataForm['imgCode'] = imgCode;
                        this.$set(this.fileList , fileList, true);
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close 跳转到订单列表
                            window.location.href = '/orderList';
                        });
                        this.loadShow = false;
                    }
                }).catch((e) => {
                    Dialog.alert({
                        message: '图片上传失败，请稍后重试',
                    }).then(() => {
                    });
                    this.loadShow = false;
                });
            },
            /**
             * 删除图片
             */
            delImg(event, e){
                let fileIndex  = e.name;
                let fileList = this.fileList;
                let fileItem = fileList[fileIndex];
                fileItem = [];//强制全清空
                this.dataForm['imgCode'][fileIndex]['id'] = 0;
                fileList[fileIndex] = fileItem;
                this.$set(this.fileList , fileList, true);

            },
            /**
             * 获取手机验证码
             */
            getMobileToken: function () {
                if(this.btnDisable){
                    return false;
                }
                this.btnDisable = true;
                let mobile = this.dataForm.mobile;
                if(this.isPhone(mobile)){
                    this.alertWarnLv = 0;
                    this.$post('/userGetRand', {
                        mobile :  mobile,
                    }).then((res) => {
                        if(res.code == 200){
                            //循环倒计时
                            this.tokenBtnMsg = this.deadline + 's';
                            this.int_btnClock = window.setInterval(() => {
                                if(this.deadline <= 0){
                                    window.clearInterval(this.int_btnClock);
                                    this.tokenBtnMsg = '获取验证码';
                                    this.deadline = 60;
                                    this.btnDisable = false;
                                    return;
                                }
                                this.deadline--;
                                this.tokenBtnMsg = this.deadline + 's';
                            },1000);
                        }else{
                            this.btnDisable = false;
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                        }
                    }).catch(e => {
                        window.console.log(e);
                        this.btnDisable = false;
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                    });
                }else{
                    Dialog.alert({
                        message: '请输入正确的手机号'
                    }).then(() => {
                        // on close
                    });
                    this.btnDisable = false;
                }
                return false
            },
            /**
             * @returns {boolean}
             */
            submitForm(){
                return false;
            },
            /**
             * 上传数据
             * @returns {boolean}
             */
            saveSubmit(){
                let data = this.dataForm;
                if(data['name'] == ''){
                    Dialog.alert({
                        message: '请输入姓名'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(data['cardID'] == '' || data['cardID'].length < 18){
                    Dialog.alert({
                        message: '请输入身份证'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(data['mobile'] == '' || !this.isPhone(data['mobile'])){
                    Dialog.alert({
                        message: '请输入手机号'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(!data['hasPhone'] && (data['token'] == '')){
                    Dialog.alert({
                        message: '请输入验证码'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }

                data['custId'] = 1058;
                //车信息
                data['typeId'] = this.$store.state.order.typeId;
                data['colorId'] = this.$store.state.order.colorId;
                //城市
                data['userProvince'] = this.$store.getters['local/getProvinceId'];
                data['userCity'] = this.$store.getters['local/getCityId'];
                data['carProvince'] = this.$store.state.order.provinceId;
                data['carCity'] = this.$store.state.order.cityId;
                //4s
                data['ssssId'] = this.$store.state.order.ssssId;
                data['hasSsss'] = this.$store.state.order.showSsss;
                //价格
                data['total'] = this.$store.state.order.carPrice;
                //优惠券
                data['couponInfo'] = this.couponInfo;
                //贷款请求
                data['loanGoodsId'] = this.loanGoodsId;
                data['loanPrice'] = this.loanPrice;

                //直接提交
                this.loadShow = true;
                this.$post('/submitOrder', data).then((res)=>{
                    window.console.log(res);
                    if(res.code == 200){
                        let data = res.data;
                        Dialog.alert({
                            message: '订单提交成功',
                        }).then(() => {
                            // on close 跳转到订单列表
                            window.location.href = '/orderDetail?orderId=' + data['orderId'] + '&mobile=' + data['mobile'];
                        });
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close 跳转到订单列表
                        });
                        this.loadShow = false;
                    }
                });

                return false;
            },
            /**
             * 上传数据
             * @returns {boolean}
             */
            savePortSubmit(){
                let data = this.dataForm;
                if(data['name'] == ''){
                    Dialog.alert({
                        message: '请输入姓名'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(data['cardID'] == '' || data['cardID'].length < 18){
                    Dialog.alert({
                        message: '请输入身份证'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(data['mobile'] == '' || !this.isPhone(data['mobile'])){
                    Dialog.alert({
                        message: '请输入手机号'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(!data['hasPhone'] && (data['token'] == '')){
                    Dialog.alert({
                        message: '请输入验证码'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }

                data['custId'] = 1058;
                //车信息
                data['typeId'] = this.$store.state.order.typeId;
                data['colorId'] = this.$store.state.order.colorId;
                //城市
                data['userProvince'] = this.$store.getters['local/getProvinceId'];
                data['userCity'] = this.$store.getters['local/getCityId'];
                data['carProvince'] = this.$store.state.order.provinceId;
                data['carCity'] = this.$store.state.order.cityId;
                //4s
                data['ssssId'] = this.$store.state.order.ssssId;
                data['hasSsss'] = this.$store.state.order.showSsss;
                //价格
                data['total'] = this.$store.state.order.carPrice;
                data['couponInfo'] = this.couponInfo;

                //直接提交
                this.loadShow = true;
                this.$post('/submitPortOrder', data).then((res)=>{
                    window.console.log(res);
                    if(res.code == 200){
                        Dialog.alert({
                            message: '订单提交成功\n咨询电话\n400-000-2787'
                        }).then(() => {
                            // on close 跳转到订单列表
                            window.location.href = '/orderList';
                        });
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close 跳转到订单列表
                        });
                        this.loadShow = false;
                    }
                });

                return false;
            },
            /**
             * 上传数据(补贴)
             * @returns {boolean}
             */
            saveBTSubmit(){
                let data = this.dataForm;
                if(data['name'] == ''){
                    Dialog.alert({
                        message: '请输入姓名'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(data['cardID'] == '' || data['cardID'].length < 18){
                    Dialog.alert({
                        message: '请输入身份证'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(data['mobile'] == '' || !this.isPhone(data['mobile'])){
                    Dialog.alert({
                        message: '请输入手机号'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(!data['hasPhone'] && (data['token'] == '')){
                    Dialog.alert({
                        message: '请输入验证码'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }

                data['custId'] = 1058;
                //车信息
                data['typeId'] = this.$store.state.order.typeId;
                //城市
                data['userProvince'] = this.$store.getters['local/getProvinceId'];
                data['userCity'] = this.$store.getters['local/getCityId'];
                data['carProvince'] = this.$store.state.order.provinceId;
                data['carCity'] = this.$store.state.order.cityId;
                //价格
                data['total'] = this.$store.state.order.carPrice;
                data['couponInfo'] = this.couponInfo;

                //直接提交
                this.loadShow = true;
                this.$post('/submitBTOrder', data).then((res)=>{
                    window.console.log(res);
                    if(res.code == 200){
                        Dialog.alert({
                            message: '订单提交成功',
                        }).then(() => {
                            // on close 跳转到订单列表
                            window.location.href = '/orderList';
                        });
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close 跳转到订单列表
                        });
                        this.loadShow = false;
                    }
                });

                return false;
            },
            /**
             * 检查字符串是否为合法手机号码
             */
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
            /**
             * 调整数字
             * @param value
             * @returns {*}
             */
            numberFormat(value) {
            var param = {};
            var k = 10000,
                sizes = ['', '万', '亿', '万亿'],
                i;
            if(value < k){
                param.value =value;
                param.unit=''
            }else{
                i = Math.floor(Math.log(value) / Math.log(k));

                param.value = ((value / Math.pow(k, i)));
                param.unit = sizes[i];
            }
            // return param;
            return param.value+param.unit;
        }
        }
    }
</script>

<style>
    /*确认订单页*/
    .confirm {
        background: #fff;
        position: relative;
        /*font-family: "PingFangSC-Medium";*/
        color: #000;
        margin-top: 6px;
        font-size: 28px;
        text-align: left;
        padding-bottom: 80px;
    }
    .confirm .title{
        background: #f4f4f4;
        font-size: 30px;
        padding: 18px 20px;
    }
    .confirm .title span{
        color: #ff0000;
        font-size: 20px;
    }
    .confirm .car-info-container{
        padding: 0 38px;
        padding-bottom: 50px;
    }
    .confirm .car-info{
        padding-left: 228px;
        position: relative;
        overflow: hidden;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 40px;
        margin-top: 38px;
    }
    .confirm .car-info span{
        position: absolute;
        left: 0;
        top: 0;
    }
    .confirm .order-info-container .cell-container{
        box-sizing: content-box;
        position: relative;
    }
    .confirm .order-info-container .cell-container img{
        position: absolute;
        left: 40px;
        top: 26px;
        margin: auto 0;
        height: 36px;
        width: 42px;
    }
    .confirm .order-info-container .van-cell{
        padding-left: 100px;
    }
    .confirm .order-info-container .van-cell__title{
        text-align: left;
    }
    .confirm .class-upload-container{
        padding: 30px 20px;
        margin-top: 36px;
    }
    .confirm .class-upload-container .upload-container{
        box-shadow: 0 0 10px 1px #c6c6c7;
        padding: 16px 20px;
    }
    .confirm .class-upload-container .upload-container .upload-title{
        font-size: 26px;
        /*font-family: "PingFangSC-Bold";*/
    }
    .confirm .class-upload-container .upload-container .upload-box{
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .confirm .upload-box .upload{
        width: 50%;
        text-align: center;
    }
    .confirm .upload-box .upload p{
        padding-bottom: 16px;
        /*font-family: "PingFangSC-Bold   ";*/
    }
    .confirm .upload-box .upload .van-uploader,.confirm .upload-box .upload .van-uploader__wrapper,.confirm .upload-box .van-uploader__upload{
        width: 100%;
    }
    .confirm .upload-box .van-uploader__upload{
        border: 1px dashed #666;
        border-radius: initial;
    }
    .confirm .upload-box .van-uploader__upload .van-uploader__upload-icon{
        border-radius: 50px;
        border: 1px solid #c2c2c2;
        padding: 10px;
        color: #c2c2c2;
    }
    .confirm .upload-box .van-uploader .van-uploader__preview{
        width: 100%;
    }
    .confirm .upload-box .van-uploader .van-uploader__preview-image{
        width: 100%;
    }
    .confirm .upload-box .van-uploader .van-uploader__preview-image, .van-image__img{
        max-height: 100%;
        max-width: 100%;
    }
    .confirm .class-upload-container .upload-container .bottom-prompt{
        font-size: 24px;
        color: #ff0000;
    }
    .confirm .bottom-submit{
        width: 100%;
        padding: 0 20px;
    }

    .confirm .bottom-submit .van-button{
        border: none;
        background-image: linear-gradient(-51deg,
        #ff4200 0%,
        #ff2727 100%),
        linear-gradient(
                #1b8fef,
                #1b8fef);
    }
	/* 雪碧图 */
	.confirm-icon {
		display: inline-block;
		position: absolute;
		left: 40px;
		top: 24px;
		margin: auto 0;
		height: 36px;
		width: 42px;
	}
	.userinf_username {
		background: url("https://qn.yiquan.com.cn/web/common/confirm-icon.png") no-repeat 0 0;
		background-size: 250px 36px;
	}
	.userinf_idcard {
		background: url("https://qn.yiquan.com.cn/web/common/confirm-icon.png") no-repeat -52px 0;
		background-size: 250px 36px;
	}
	.userinf_workno {
		background: url("https://qn.yiquan.com.cn/web/common/confirm-icon.png") no-repeat -104px 0;
		background-size: 250px 36px;
	}
	.userinf_mail {
		background: url("https://qn.yiquan.com.cn/web/common/confirm-icon.png") no-repeat -156px 0;
		background-size: 250px 36px;
	}
	.userinf_phone {
		background: url("https://qn.yiquan.com.cn/web/common/confirm-icon.png") no-repeat -208px 0;
		background-size: 250px 36px;
	}
</style>