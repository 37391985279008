/**
 * 贷款共享信息
*/
const loan = {
    namespaced: true,
    state : {
        price : 0,
        bankGoodsInfo : []
    },
    mutations: {
        setPrice(state, price) {
            state.price = price;
        },
        setGoodsInfo(state, bankGoodsInfo) {
            state.bankGoodsInfo = bankGoodsInfo;
        },
    },
    getters : {
        getPrice(state) {
            return state.price
        },
        getGoodsInfo(state) {
            return state.bankGoodsInfo
        },
    },
    actions: {

    }
};

export default  loan;