<template>
    <div class="books-index">
        <div class="books-list">
            <div class="books-mark-item" @click="navTolist(1)">
                <img class="one" src="/images/book/b-bk.png">
                <div class="bg bk"></div>
            </div>
            <div class="books-mark-item" @click="navTolist(3)">
                <img class="one"  src="/images/book/b-bk.png">
                <div class="bg kdlk"></div>
            </div>
            <div class="books-mark-item" @click="navTolist(2)">
                <img class="one" src="/images/book/b-bk.png">
                <div class="bg xfl"></div>
            </div>
            <div class="books-mark-item" @click="navTolist(4)">
                <img class="one" src="/images/book/b-xny.png">
                <div class="bg xny"></div>
            </div>
<!--            <div class="books-mark-bottom">-->
<!--                <div class="bottom-item" @click="showLocalPopup">-->
<!--                    地区：<span>{{localVal}}</span>-->
<!--                </div>-->
<!--                <div class="bottom-item" @click="navToOrderListForm">-->
<!--                    我的订单-->
<!--                </div>-->
<!--            </div>-->

            <div class="books-mark-left">
                <div class="right-item" @click="showLocalPopup">
                    <img class="right-img" src="https://qn.yiquan.com.cn/books/position.png">
                </div>
            </div>

            <div class="books-mark-right">
                <div class="right-item" @click="navToOrderListForm">
                    <img class="right-img" src="https://qn.yiquan.com.cn/books/buy-car.png">
                </div>
                <div class="right-item" @click="callTel">
                    <img class="right-img" src="https://qn.yiquan.com.cn/books/c-service.png">
                </div>
            </div>

        </div>

        <div class="orientation-box">
            <div class="orientation">请锁定屏幕<br>以竖屏方式浏览</div>
        </div>

        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>


        <van-popup v-model="showlocalPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom >
            <van-picker
                    title="请选择所在城市"
                    show-toolbar
                    :columns="columns"
                    value-key = "content"
                    @change="onLocalChange"
                    @cancel="showlocalPicker = false"
                    @confirm="onLocalConfirm"
            ></van-picker>
        </van-popup>

        <van-popup class="order-form-popup" v-model="showPicker2" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <div class="nav-list-form">
                订单信息查询
                <span class="close-btn" @click="showPicker2 = false">取消</span>
            </div>
            <div class="mobile-form">
                <van-field v-model="mobile" :rules="[{ required: true, message: '手机号' }]" label="手机号:" placeholder="请输入手机号" :border="true"/>
            </div>
            <div class="mobile-form-submit" @click="navToOrder">确认</div>
        </van-popup>
    </div>
</template>

<script>
    let citys = {};
    import vue from 'vue';

    import {Dialog} from 'vant';

    vue.use(Dialog);
    export default {
        name: 'books-index',
        created: function(){
            let that = this;
            let cityList = "";
            if(that.$store.getters['local/getCityList'] != undefined && that.$store.getters['local/getCityList'] != '') {
                cityList = JSON.parse(that.$store.getters['local/getCityList']);
            }

            let timestamp = Date.now();
            let timeQuantum = 86400000;
            if(cityList != '') {
                timeQuantum = Number(timestamp) - Number(cityList.timestamp)
            }
            if(timeQuantum >= 86400000 || cityList == '') {
                //获取城市信息
                that.getCityList();
            }
            else {
                citys = cityList.data;
                let brandKey = [];
                if(that.$store.getters['local/getBrandKey'] != undefined && that.$store.getters['local/getBrandKey'] != null && this.$store.getters['local/getBrandKey'].length != 0) {
                    brandKey = JSON.parse(that.$store.getters['local/getBrandKey']);
                }else {
                    brandKey = [0,0];
                }
                that.citys = citys;
                that.columns = [
                    {
                        values: citys,
                        className: 'column1',
                        defaultIndex: brandKey[0],
                    },
                    {
                        values: citys[brandKey[0]]['list'],
                        className: 'column2',
                        defaultIndex: brandKey[1],
                    }
                ];
                let brandLoacl = that.$store.getters['local/getBrandLocal'];
                if(brandLoacl.length > 0){
                    let brandKey = that.$store.getters['local/getBrandKey'];
                    that.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
                }
            }
        },
        data() {
            return {
                loadShow : false,
                dataForm: {
                    branch: '',
                    name: '',
                    mobile : '',
                    rand : '',
                },
                branchTitle: '所属单位',
                hasBranch : '',
                showlocalPicker: false,
                localVal: '未知',
                localVals : [],
                citys : citys,
                columns: [
                    {
                        values: Object.keys(citys),
                    },
                    {
                        values: '',
                        className: 'column2',
                        defaultIndex: 2
                    }
                ],
                showPicker2 : false,
                mobile: ''
            }
        },
        methods: {
            submitForm(){
                return false;
            },
            navToOrderListForm(){
                this.showPicker2 = true;
            },
            /**
             * 展示地址底部弹窗
             */
            showLocalPopup(){
                this.$data.showlocalPicker = true;
            },
            /**
             * 地址选择变化
             */
            onLocalChange(picker) {
                let indexs = picker.getIndexes();
                picker.setColumnValues(1, this.citys[indexs[0]]['list']);
            },
            /**
             * 地址选择确认
             */
            onLocalConfirm(value, key){
                this.localVal = value[0]['content'] + '-' + value[1]['content'];
                this.localVals = value;
                let valStr = JSON.stringify(value);
                this.$store.commit('local/setBrandLocal', valStr);
                this.$store.commit('local/setBrandKey', JSON.stringify(key));
                this.$data.showlocalPicker = false;
                if(this.firstCity) {
                    this.saveUserCity();
                }
            },
            //获取城市信息
            getCityList() {
                let that = this;
                that.$post('/getCityList', {
                }).then((res) => {
                    if(res.code == 200){
                        citys = res.data;
                        //创建一个时间戳
                        let time = Date.now();
                        let cityArr = {};
                        cityArr.data = citys;
                        cityArr.timestamp = time;
                        this.$store.commit('local/setCityList', JSON.stringify(cityArr));
                        that.citys = citys;
                        let brandKey = [];
                        if(this.$store.getters['local/getBrandKey'] != undefined && this.$store.getters['local/getBrandKey'] != null && this.$store.getters['local/getBrandKey'].length != 0) {
                            brandKey = JSON.parse(this.$store.getters['local/getBrandKey']);
                        }else {
                            brandKey = [0,0];
                        }
                        that.columns = [
                            {
                                values: citys,
                                className: 'column1',
                                defaultIndex: brandKey[0],
                            },
                            {
                                // values: citys[0]['list'],
                                values: citys[brandKey[0]]['list'],
                                className: 'column2',
                                defaultIndex: brandKey[1],
                            }
                        ];
                        let brandLoacl = this.$store.getters['local/getBrandLocal'];
                        if(brandLoacl.length > 0){
                            let brandKey = this.$store.getters['local/getBrandKey'];
                            this.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
                        }
                    }else{
                        Dialog.alert({
                            message: res.msg
                        }).then(() => {
                            // on close
                        });
                    }
                }).catch(
                    (e) =>{
                        window.console.log(e);
                    }
                );
            },
            navTolist(booksMarkId){
                if(this.localVal == '未知'){
                    Dialog.alert({
                        message: '请选择地区'
                    }).then(() => {
                        this.showLocalPopup()
                    });
                    return false;
                }
                let provinceId = this.localVals[0]['id'];
                let cityId = this.localVals[1]['id'];

                this.$store.commit('local/setProvinceId', provinceId);
                this.$store.commit('local/setCityId', cityId);
                this.$store.commit('local/setLocal', this.localVal);

                if(booksMarkId == 1 || booksMarkId == 4){
                    this.$router.push({
                        path: '/books/carlist',
                        query: {
                            booksMarkId : booksMarkId,
                            type : 1,
                        }
                    })
                }else{
                    this.$router.push({
                        path: '/books/carlist',
                        query: {
                            booksMarkId : booksMarkId,
                            type : 2,
                        }
                    })
                }
            },
            navToOrder(){
                this.$router.push({
                    path: '/orderList',
                    query: {
                        mobile : this.mobile,
                    }
                })
            },
            callTel(){
                window.location.href = 'tel://400-000-2787';
            }
        }
    }
</script>

<style>
    /*主体*/
    page{
        height: 100%;
    }
    .books-index {
        background: #262728;
        height: 100%;
        width: 100%;
    }
    .books-index .books-list{
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        /*padding-bottom: 80px;*/
    }
    .books-index .books-list .books-mark-item{
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .books-index .books-list .books-mark-item img{
        width: 100%;
    }
    .books-index .books-list .books-mark-item .one{
        opacity: 0;
    }
    .books-index .books-list .books-mark-item .bg{
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
    }
    .books-index .books-list .books-mark-item .bg.bk{
        background-image: url("/images/book/b-bk.png");
        background-size: 100% auto;
        background-position:center;
    }
    .books-index .books-list .books-mark-item .bg.xfl{
         background-image: url("/images/book/b-xfl.png");
         background-size: 100% auto;
        background-position:center;
     }
    .books-index .books-list .books-mark-item .bg.kdlk{
          background-image: url("/images/book/b-kdlk.png");
          background-size: 100% auto;
        background-position:center;
    }
    .books-index .books-list .books-mark-item .bg.xny{
          background-image: url("/images/book/b-xny.png");
          background-size: 100% auto;
        background-position:center;
    }

    .books-index .books-list .books-mark-bottom{
        position: absolute;
        bottom: 0;
        left: 0;
        background: #f1f1f1;
        height: 80px;
        width: 100%;
        line-height: 80px;
        display: flex;
        font-size: 24px;
    }
    .books-index .books-list .books-mark-bottom .bottom-item{
        width: 50%;
    }
    .books-index .books-list .books-mark-bottom .bottom-item:first-child{
        border-right: #cfcfcf solid 1px;
    }

    .books-index .books-list .books-mark-right{
        position: absolute;
        right: 20px;
        display: flex;
        flex-wrap: wrap;
        width: 72px;
        top: 64%;
    }

    .books-index .books-list .books-mark-right .right-item{
        width: 72px;
        height: 72px;
        background: rgba(125,125,125,0.6);
        border-radius: 50%;
    }

    .books-index .books-list .books-mark-right .right-item:first-child{
        margin-bottom: 20px;
    }

    .books-index .books-list .books-mark-right .right-img{
        width: 100%;
        padding: 12px;
    }


    .books-index .books-list .books-mark-left{
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        width: 72px;
        top: 20px;
        left: 20px;
    }

    .books-index .books-list .books-mark-left .right-item{
        width: 72px;
        height: 72px;
        background: rgba(125,125,125,0.6);
        border-radius: 50%;
    }

    .books-index .books-list .books-mark-left .right-item:first-child{
        margin-bottom: 20px;
    }

    .books-index .books-list .books-mark-left .right-img{
        width: 100%;
        padding: 12px;
    }

    .books-index .wrapper{
        padding: 0 95px;
    }
    .books-index .wrapper .order-box{
        background: #fff;
        width: 100%;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        padding-bottom: 42px;
    }

    .books-index .wrapper .order-box .order-title{
        width: 100%;
    }

    .books-index .wrapper .order-box .order-title img{
        width: 100%;
    }

    .books-index .wrapper .order-box .order-form{
        padding: 60px 36px;
        padding-bottom: 0;
    }

    .books-index .wrapper .order-box .order-form .cell-container{
        position: relative;
        padding-top: 32px;
    }

    .books-index .wrapper .order-box .order-form .cell-container .van-cell{
        border: solid 1px rgba(160,160,160,.8);
    }

    .books-index .wrapper .order-box .order-form .cell-container:first-child{
        padding-top: 0;
    }

    .books-index .wrapper .order-box .order-form .cell-container.token-cell .van-cell{
        width: 60%;
    }

    .books-index .wrapper .order-box .order-form .cell-container.token-cell .getToken{
        color: #fff;
        display: inline-block;
        background-color: #352c2b;
        border-radius: 10px;
        position: absolute;
        right: 0;
        top: 32px;
        height: 76px;
        line-height: 76px;
        font-size: 24px;
        padding: 0 20px;
        box-shadow: none;
        width: 166px;
    }

    .books-index .wrapper .order-box .order-form .cell-container .van-cell.van-cell--clickable{
        border-radius: 10px;
        border: solid 1px #a0a0a0;
        padding: 0 25px;
        font-size: 26px;
        height: 76px;
        line-height: 76px;
        text-align: left;
        color: #999999;
    }

    .books-index .wrapper .order-box .order-form .warn-info{
        text-align: left;
        font-size: 26px;
        color: #f93204;
        height: 50px;
        line-height: 50px;
        padding-left: 60px;
    }
    
    .books-index .wrapper .order-box .order-form .cell-container.hasBranch .van-cell.van-cell--clickable{
        color: #000;
    }
    
    .books-index .wrapper .order-box .order-form .cell-container .van-cell.van-cell--clickable .van-icon{
        margin: auto 0;
        margin-left: 5px;
    }

    .books-index .wrapper .order-box .order-form .cell-container .van-field{
        border-radius: 10px;
        border: solid 1px #a0a0a0;
        padding: 0 25px;
        font-size: 26px;
        height: 76px;
        line-height: 76px;
    }

    .books-index .wrapper .order-box .order-form .cell-container .cell-title{
        text-align: left;
        color: #4a4a4a;
        font-size: 24px;
    }

    .books-index .wrapper .order-box .order-form .bottom-submit{
        margin-top: 64px;
    }

    .books-index .wrapper .order-box .order-form .bottom-submit .van-button{
        border: none;
    }

    .books-index .wrapper .order-box .order-form .bottom-submit .van-button{
        height: 78px;
        line-height: 78px;
        color: #fff;
        font-size: 28px;
        background-image: linear-gradient(90deg,
        #aa8f62 0%,
        #d8c08c 100%);
        box-shadow: 0px 6px 27px 0px
        rgba(59, 48, 51, 0.35);
        border-radius: 10px;
    }

    /*选择部门弹窗*/
    .books-index .order-form-popup{
        width: 100%;
        padding: 0 30px;
        padding-bottom: 20px;
    }

    .books-index .order-form-popup .nav-list-form{
        height: 80px;
        line-height: 80px;
    }

    .books-index .order-form-popup .nav-list-form .close-btn{
          position: absolute;
          color: #000;
          font-style: 24px;
          right: 18px;
      }

    .books-index .order-form-popup .mobile-form{
        width: 100%;
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .books-index .order-form-popup .mobile-form-submit{
        width: 90%;
        background: #1b8fef;
        color: #fff;
        height: 80px;
        line-height: 80px;
        margin:  auto;
        border-radius: 50px;
        margin-top: 40px;
    }


    .books-index .order-form-popup .mobile-form .van-field__body{
        border-bottom: 1px solid #dadada;
    }

    .books-index .orientation-box{
        display: none;
    }


    @media screen and (min-width: 420px) and (orientation: landscape){
        .books-index .orientation-box {
            display:block;
            position: absolute;
            background-color: #000!important;
            height: 100%;
            width: 100%;
            top: 0;
        }

        .books-index .orientation::before{
            background: url('/images/icon-bg-b-width.png') no-repeat center 40% #000;
            background-size: 100%;
            bottom: 100%;
            content: "";
            height: 60px;
            left: 50%;
            position: absolute;
            -webkit-transform: translate(-50%,-10px);
            transform: translate(-50%,-10px);
            width: 110px;
        }

        .books-index .orientation {
            color: #fff;
            display: block;
            font-size: 18px;
            line-height: 24px;
            position: absolute;
            text-align: center;
            top: 50%;
            width: 100%;
        }
    }
</style>
