import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import local from './modules/local';
import order from './modules/order';
import loan from './modules/loan';

Vue.use(Vuex);

const store = new Vuex.Store({
    state:{
        test:'123'
    },
    modules:{
        user,
        local,
        order,
        loan
    }
});

export default store;