<template>
    <div class="selection">
        <div class="item-container">
            <div class="img-container">
                <img class="item-img" :src="series.img">
            </div>
            <div class="info-container">
                <div class="series-name">{{series['mark_name']}} <span v-if="series.year != '' && series.year != null">{{series['year']}}款</span> {{series['type_name']}} {{series['config_info']}}</div>
                <div class="guide-price">指导价: {{series.guide_price}}</div>
                <div class="guide-price" v-if="downPrice != 0">优惠券: {{downPrice}}</div>
                <div class="current-price-container" v-if="series.ACTIVITYTYPE == 1">
                    <div class="tag2">补贴:</div>
                    <span class="price">{{series.SUBPLICY}}</span>
                </div>
                <div class="current-price-container current-price-container2" v-if="series.ACTIVITYTYPE == 1">
                    <div class="tag2">补贴金额:</div>
                    <span class="price">{{series.sub_price}}元</span>
                </div>
                <div class="current-price-container" v-else>
                    <div class="tag">
<!--                        <img class="tag-img" src="/images/tag/tag-1.png">-->
                        <div class="tag-img-icon">{{custMini}}员工价</div>
                    </div>
                    <span class="price">{{series.sub_price}}</span>
                </div>
            </div>
        </div>
        <div class="container-center">
            <div>
                <div class="cell-container city-cell">
                    <van-cell is-link @click.stop title="所在城市:" :value="oldLocal"/>
                    <!-- <img src="/images/icon/order-city.png"> -->
					<i class="selection-icon order-city"></i>
                </div>
                <div class="cell-container city2-cell">
                    <van-cell is-link @click="showLocalPopup" title="上牌城市:" :value="localVal"/>
                    <!-- <img src="/images/icon/order-sp-city.png"> -->
					<i class="selection-icon order-sp-city"></i>
                </div>
                <div class="cell-container color-cell" v-if="series.ACTIVITYTYPE != 1">
                    <van-cell is-link @click.stop title="外观\内饰:"  @click="showHideColorView">
                        <template slot="default">
                            <img class="cell-img" v-if="colorItem.file != ''" :src="colorItem.file">
                        </template>
                    </van-cell>
                    <!-- <img src="/images/icon/order-trim.png"> -->
					<i class="selection-icon order-trim"></i>
                </div>
                <ul class="color-list" v-if="series.ACTIVITYTYPE != 1" :class="colorcloseView">
                    <li v-for="(item, index) in  colorList" :key="index" class="brand-item" @click="changeColor(index)">
                        <img class="color-img" :src="item.file">
                        <p>{{item.name}}</p>
                    </li>
                    <li class="empety-box"></li>
                </ul>
                <div class="cell-container city2-cell" v-if="markId == 1054 && loanType ==2">
                    <van-cell is-link title="贷款方案:" :value="bankVal" @click="navToBank"/>
                    <img src="/images/icon/order-loan.png">
                </div>
                <div v-if="series.show_ssss" class="cell-container 4s-cell" @click="showHideSsssView">
                    <van-cell is-link title="提车地点:" class="ssss-val" :value="ssssVal"/>
                    <img src="/images/icon/order-4s.png">
                </div>
                <ul v-if="series.show_ssss" class="ssss-list" :class="sssscloseView">
                    <li v-for="(item, index) in  ssssList" :key="index">
                        <div class="ssss-item" :class="item.css" @click="changeSsss(index)">
                            <p>[4s]{{item.name}}</p>
                            <div style="opacity: 0;position: absolute">{{ssssIndex}}</div>
                            <p class="ssss-local">
                                <span></span>
                                {{item.address}}
                                <img src="/images/icon_area.png">
                            </p>
                        </div>
                    </li>
                    <li class="empety-box"></li>
                </ul>
                <div class="buy-btn five-btn">
                    <van-button type="info" size="noraml" @click="navToOrder">下一步</van-button>
                </div>
            </div>
        </div>
        <van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <van-picker
                    title="请选择所在城市"
                    show-toolbar
                    :columns="columns"
                    value-key = "content"
                    @change="onLocalChange"
                    @cancel="showPicker = false"
                    @confirm="onLocalConfirm"
            ></van-picker>
        </van-popup>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, Cell, Icon, Popup, Picker, Dialog} from 'vant';

    vue.use(Button).use(Cell).use(Icon).use(Popup).use(Picker).use(Dialog);

    let citys = {};
    export default {
        name: 'order-selection',
        data() {
            return {
                series:{
                    mark_name: '-',
                    year : '',
                    config_info : '-',
                    type_name : '-',
                    img: '/images/series-2.jpg',
                    max_down: 8.20,
                    guide_price: "-",
                    sub_price: "-",
                },
                value: '',
                showPicker: false,
                showPicker2 : false,
                citys : citys,
                columns: [
                    {
                        values: Object.keys(citys),
                    },
                    {
                        values: citys['浙江'],
                        className: 'column2',
                        defaultIndex: 2
                    }
                ],
                oldLocal : '',
                localVal : "请选择所在城市",
                localVals : [],
                colorList : [],
                colorItem : {
                    id : 0,
                    name : '',
                    file : '',
                },
                bankVal : '',
                ssssVal: "选择交车的经销商",
                ssssList : [],
                ssssIndex : '',
                loadShow: false,
                downPrice : 0,
                usedUserCouponId : 0,
                couponId : 0,
                custMini : this.$store.getters['user/getCustMini'],
                colorcloseView : '',
                sssscloseView : '',
                markId: 0,
                old_sub_price : 0,
                loanType : 1,
            }
        },
        created: function(){
            this.loadShow = true;
            let query = this.$route.query;
            if (typeof query.markId == 'undefined' || typeof query.markTypeId == 'undefined' || typeof query.id == 'undefined') {
                this.$router.replace({
                    path: '/index'
                });
                return false;
            }

            this.loanType = query.loanType == 2 ? 2 : 1;

            let markId = query.markId;
            this.markId = markId;
            // let markTypeId = query.markTypeId;
            let typeId = query.id;
            let provinceId = this.$store.getters['local/getProvinceId'];
            let cityId = this.$store.getters['local/getCityId'];
            let custId = 1058;

            if ((typeof provinceId == 'undefined' || provinceId == 0 || provinceId == null) || (typeof cityId == 'undefined' || cityId == 0 || cityId == null)) {
                this.$router.replace({
                    path: '/index'
                });
                return false;
            }
            this.$post('getColoInfo', {
                provinceId: provinceId,
                cityId: cityId,
                custId: custId,
                markId: markId,
                typeId: typeId
            }).then((res) => {
                if(res.code == 200){
                    let data = res.data;
                    if(data.ACTIVITYTYPE == 5){
                        data['sub'] = this.numberFormat(data['guide_price'] - data['sub_price']);
                        data['guide_price'] = this.numberFormat(data['guide_price']);
                        data['old_sub_price'] = Object.assign(data['sub_price']);
                        data['sub_price'] = this.numberFormat2(data['sub_price']);
                    }else{
                        data['sub'] = this.numberFormat(data['guide_price'] - data['sub_price']);
                        data['guide_price'] = this.numberFormat(data['guide_price']);
                        data['old_sub_price'] = Object.assign(data['sub_price']);
                        data['sub_price'] = this.numberFormat(data['sub_price']);
                    }
                    if(data['banner'].length > 0){
                        data['img'] = data['banner'][0]['file'];
                    }else{
                        data['img'] = '';
                    }
                    this.series=res.data;
                    this.colorList=data.colors;
                    this.loadShow = false;
                    this.oldLocal = this.$store.getters['local/getLocal'];
                    this.localVal = this.$store.getters['local/getLocal'];
                    this.localVals = [
                        { id : this.$store.getters['local/getProvinceId']},
                        { id : this.$store.getters['local/getCityId']}
                    ];
                    if(data.show_ssss){
                        let ssssList = data.ssss;
                        let len = ssssList.length;
                        for (let i = 0;i < len; i++){
                            ssssList[i]['css'] = '';
                        }
                        this.ssssList = ssssList;
                    }
                    //判断是否有优惠券
                    let couponInfo = this.$store.state.order.couponInfo;
                    couponInfo = JSON.parse(couponInfo);
                    if(couponInfo.length > 0){
                        let downPrice = 0;
                        let len  = couponInfo.length;
                        for (var i = 0; i < len ; i++){
                            downPrice += couponInfo[i]['downPrice'];
                        }
                        data['sub_price'] = this.numberFormat(data['old_sub_price'] - downPrice);
                    }

                    this.old_sub_price = data['old_sub_price'];
                }else {
                    Dialog.alert({
                        message: res.msg
                    }).then(() => {
                        // on close
                        this.$router.go(-1);
                    });
                }
            });
			let cityList = "";
			if(this.$store.getters['local/getCityList'] != undefined && this.$store.getters['local/getCityList'] != '') {
				cityList = JSON.parse(this.$store.getters['local/getCityList']);
			}
			let timestamp = Date.now();
			let timeQuantum = 86400000;
			if(cityList != '') {
				timeQuantum = Number(timestamp) - Number(cityList.timestamp)
			}
			console.log(cityList);
			if(timeQuantum >= 86400000 || cityList == '') {
				//获取城市信息
				this.$post('/getCityList', {
				}).then((res) => {
					if(res.code == 200){
						citys = res.data;
						//创建一个时间戳
						let time = Date.now();
						let cityArr = {};
						cityArr.data = citys;
						cityArr.timestamp = time;
						this.$store.commit('local/setCityList', JSON.stringify(cityArr));
						this.citys = citys;
						let brandKey = [];
						if(this.$store.getters['local/getBrandKey'] != undefined && this.$store.getters['local/getBrandKey'] != null && this.$store.getters['local/getBrandKey'].length != 0) {
							brandKey = JSON.parse(this.$store.getters['local/getBrandKey']);
						}else {
							brandKey = [0,0];
						}
						this.columns = [
							{
								values: citys,
								className: 'column1',
								defaultIndex: brandKey[0],
							},
							{
								values: citys[brandKey[0]]['list'],
								className: 'column2',
								defaultIndex: brandKey[1],
							}
						];
					}else{
						Dialog.alert({
							message: res.msg
						}).then(() => {
							// on close
						});
					}
				}).catch(
					(e) =>{
						window.console.log(e);
					}
				);
			}else {
				console.log("读取缓存内的信息！");
				citys = cityList.data;
				let brandKey = [];
				if(this.$store.getters['local/getBrandKey'] != undefined && this.$store.getters['local/getBrandKey'] != null && this.$store.getters['local/getBrandKey'].length != 0) {
					brandKey = JSON.parse(this.$store.getters['local/getBrandKey']);
				}else {
					brandKey = [0,0];
				}
				this.citys = citys;
				this.columns = [
					{
						values: citys,
						className: 'column1',
						defaultIndex: brandKey[0],
					},
					{
						values: citys[brandKey[0]]['list'],
						className: 'column2',
						defaultIndex: brandKey[1],
					}
				];
			}
        },
        mounted: function(){
            if(this.markId == 1054){
                let loanGoodsInfo = this.$store.state.loan.bankGoodsInfo;
                console.log(loanGoodsInfo);
                if(loanGoodsInfo.ID != undefined && loanGoodsInfo.ID != 0){
                    this.bankVal = '¥'+loanGoodsInfo['PRICE']+'    '+loanGoodsInfo['YEAR'] + '年('+loanGoodsInfo['NUMBER']+'期)';
                }
            }
        },
        methods: {
            showLocalPopup(){
                this.$data.showPicker = true;
            },
            /**
             * 地址选择变化
             */
            onLocalChange(picker) {
                let indexs = picker.getIndexes();
                picker.setColumnValues(1, this.citys[indexs[0]]['list']);
            },
            /**
             * 地址选择确认
             */
            onLocalConfirm(value){
                this.localVal = value[0]['content'] + '-' + value[1]['content'];
                this.localVals = value;
                this.hasLocalCss = "hasLocalCss";
                this.$data.showPicker = false;
            },
            /**
             * 改变4颜色
             * @param index
             */
            changeColor(index){
                let colorItem = this.colorList[index];
                this.colorItem = colorItem;
            },
            /**
             * 改变4s地址
             * @param index
             */
            changeSsss(index){
                let ssssList = this.ssssList;
                let len = ssssList.length;
                let ssssVal = '选择交车的经销商';
                for (let i = 0;i < len; i++){
                    let ssssClass = i;
                    if(index == i){
                        ssssClass = 'choose';
                        ssssVal = ssssList[i]['address'];
                    }
                    ssssList[i]['css'] = ssssClass;
                }
                this.ssssList = ssssList;
                this.ssssIndex = index;
                this.ssssVal = ssssVal;
            },
            /**
             * 跳转到注册订单
             * @returns {boolean}
             */
            navToOrder(){
                //存地址
                let localList = this.localVals;
                if(localList.length <= 0){
                        Dialog.alert({
                            message: '请选择上牌城市'
                        }).then(() => {
                            // on close
                        });
                        return false;
                }
                let provinceId = localList[0]['id'];
                let cityId = localList[1]['id'];
                this.$store.commit('order/setProvinceId', provinceId);
                this.$store.commit('order/setCityId', cityId);
                if(this.series.ACTIVITYTYPE != 1){
                    //非bt需要选择颜色
                    //存颜色
                    let color = this.colorItem;
                    if(color['id'] == 0){
                        Dialog.alert({
                            message: '请选择内外装饰'
                        }).then(() => {
                            // on close
                        });
                        return false;
                    }
                    this.$store.commit('order/setColorId', color['id']);
                    this.$store.commit('order/setColorVal', color['name']);
                }else{
                    //补贴填写颜色为0
                    this.$store.commit('order/setColorId', 0);
                }

                //设置4s店地址
                let showSsss = this.series['show_ssss'];
                this.$store.commit('order/setShowSsss', showSsss);
                if(showSsss){
                    //展示sss,存入ssss信息
                    if(this.ssssIndex === ''){
                        Dialog.alert({
                            message: '请选择提车地点'
                        }).then(() => {
                            // on close
                        });

                        return false;
                    }
                    let ssss = this.ssssList[this.ssssIndex];
                    this.$store.commit('order/setSsssId', ssss['id']);
                }

                let query = this.$route.query;
                let markId = query.markId;
                let typeId = query.id;
                this.$store.commit('order/setMarkId', markId);
                this.$store.commit('order/setTypeId', typeId);
                let carName = this.series['mark_name'];
                if(this.series['year'] != '' && this.series['year'] != null){
                    carName += this.series['year'] + '款';
                }
                carName += this.series['type_name'] + this.series['config_info'];
                this.$store.commit('order/setCarVals', carName);
                this.$store.commit('order/setCarPrice', this.series['old_sub_price']);
                this.$store.commit('order/setGuidePrice', this.series['guide_price']);

                //跳转
                this.$router.push({path : 'confirm' });
                return false;
            },
            numberFormat(value) {
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value;
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }
                // return param;
                return param.value+param.unit;
            },
            numberFormat2(value){
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }

                let reg = /^[0-9]/;
                param.value = param.value.toString();
                let len = param.value.length;
                let newValue = '';
                for (var i = 0; i < len ; i++){
                    let item = param.value.charAt(i);
                    if(i != 0){
                        item = item.replace(reg, 'x');
                    }
                    newValue += item;
                }

                // return param;
                return newValue+param.unit;
            },
            navToBank(){
                this.$store.commit('loan/setPrice', this.old_sub_price);
                this.$router.push({
                    path : '/orderLoan',
                    query:{
                        markId : this.markId
                    }
                });
            },
            showHideColorView(){
                if(this.colorcloseView == 'close-view'){
                    this.colorcloseView = '';
                }else{
                    this.colorcloseView = 'close-view';
                }
            },
            showHideSsssView(){
                if(this.sssscloseView == 'close-view'){
                    this.sssscloseView = '';
                }else{
                    this.sssscloseView = 'close-view';
                }
            }
        }
    }
</script>

<style>
    /*车详情页*/
    .selection {
        background: #fff;
        position: relative;
        padding-bottom: 120px;
        min-height: 100%;
    }
    .selection .item-container{
        position: relative;
        padding: 0 24px;
        padding-top: 36px;
        min-height: 240px;
        padding-left: 300px;
        box-sizing: content-box;
    }
    .selection .item-container .img-container{
        position: absolute;
        left: 24px;
        width: 240px;
    }
    .selection .item-container .img-container .item-img{
        width: 240px;
        height: 180px;
    }
    .selection .item-container .info-container{

    }
    .selection .item-container .info-container .series-name{
        overflow: hidden;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 30px;
        line-height: 30px;
        text-align: left;
        line-height: 1.2;
        /*font-family: "PingFangSC-Bold";*/
    }
    .selection .item-container .info-container .guide-price{
        color: #979797;
        font-size: 28px;
        margin-top: 30px;
        text-align: left;
    }
    .selection .info-container .current-price-container{
        position: relative;
        margin-top: 20px;
        text-align: left;
        font-size: 26px ;
    }
    .selection .info-container .current-price-container2{
         position: relative;
         margin-top: 10px;
         padding-bottom: 10px;
         text-align: left;
         font-size: 26px ;
     }
    .selection .info-container .current-price-container .tag{
        color: #fff;
        display: inline-block;
        letter-spacing: 0;
        padding: 10px 0;
        border-radius: 15px;
        left: 0;
    }

    .selection .info-container .current-price-container .tag .tag-img{
        height: 44px;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 4px;
    }

    .selection .info-container .current-price-container .tag .tag-img-icon{
        height: 44px;
        line-height: 44px;
        color: #ffffff;
        font-size: 22px;
        font-weight: bold;
        background-image: linear-gradient(-56deg,
        #ff6000 0%,
        #ff0000 100%),
        linear-gradient(
                #ff3036,
                #ff3036);
        background-blend-mode: normal,
        normal;
        box-shadow: 0px 4px 1px 0px rgba(243, 7, 0, 0.2);
        border-radius: 6px;
        padding: 0 8px;
        letter-spacing: 1px;
    }

    .selection .info-container .current-price-container .tag2{
        color: #f30700;
        display: inline-block;
        letter-spacing: 0;
        padding: 10px 18px;
        padding-left: 0;
        border-radius: 15px;
        left: 0;
    }
    .selection .info-container .current-price-container .price{
        color: #f93204;
        display: inline-block;
        /*font-family: "PingFangSC-Bold";*/
        letter-spacing: 0;
        padding: 10px 18px;
        border-radius: 15px;
        left: 0;
        height: 44px;
        line-height: 44px;
        box-sizing: content-box;
    }
    .selection .container-center{
        width: 100%;
        border-top: 20px solid #f2f9ff;
        color: #999;
    }
    .selection .container-center .cell-container{
        box-sizing: content-box;
        position: relative;
    }
    .selection .container-center .cell-container img{
        position: absolute;
        left: 40px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 40px;
        width: 40px;
    }
    .selection .container-center .van-cell{
        padding-left: 100px;
    }
    .selection .container-center .van-cell__title{
        text-align: left;
    }
    .selection .container-center .city-cell .van-icon-arrow:before{
        opacity: 0;
    }
    .selection .container-center .cell-container img.cell-img{
        right: 0;
        left: auto;
    }
    .selection .container-center .color-list{
        background: #fbfbfb;
        display: flex;
        padding-left: 90px;
        border-bottom: 1px solid #ebedf0;
        overflow: auto;
        overflow-scrolling: touch;
        transition: max-height 1s;
        max-height: 400px;
        box-sizing: border-box;
    }

    .selection .container-center .color-list.close-view{
        max-height: 0;
        padding-left: 90px;
        transition: max-height 0.5s;
    }
    .selection .container-center .color-list::-webkit-scrollbar {display:none}
    .selection .container-center .color-list li{
        padding:  30px 0;
        padding-left: 34px;
        white-space: nowrap;
    }
    .selection .container-center .color-list li.empety-box{
        padding-right: 90px;
    }
    .selection .container-center .color-list .color-img{
        width: 56px;
        height: 56px;
        border-radius: 15px;
        overflow: hidden;
    }
    .selection .container-center .color-list p{
        color: #323233;
        font-size: 20px;
        margin-top: 10px;
    }

    .selection .buy-btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    .selection .buy-btn .van-button {
        width: 100%;
    }
    
    .selection .ssss-list{
        color: #000;
        font-size: 28px;
        text-align: left;
        padding: 0 20px;
        transition: max-height 1s;
        max-height: 3000px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .selection .ssss-list.close-view{
        max-height: 0;
        transition: max-height 0.5s;
    }
    .selection .ssss-list .ssss-item{
        border: 1px solid #c8c8c8;
        padding: 10px 20px;
        border-radius: 15px;
        line-height: 50px;
        margin-top: 20px;
    }
    .selection .ssss-list .ssss-item.choose{
        border: 1px solid #30a4c8;
        color: #30a4c8;
    }
    .selection .ssss-list li{
        margin-top: 20px;
    }
    .selection .ssss-list li:first-child{
        margin-top: 0;
    }
    .selection .ssss-list .ssss-item img{
        width: 26px;
        height: 26px;
        position: absolute;
        left: 0;
        top: 10px;
    }
    .selection .ssss-list .ssss-item .ssss-local{
        position: relative;
    }
    .selection .ssss-list .ssss-local span{
        padding-left: 32px;
    }
    .selection .ssss-val .van-cell__value span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }


    .selection .buy-btn.five-btn .van-button{
        border: none;
        background-image: linear-gradient(-51deg,
        #ff4200 0%,
        #ff2727 100%),
        linear-gradient(
                #1b8fef,
                #1b8fef);

        box-shadow: 0px 11px 40px 0px rgba(229, 26, 4, 0.38);
        border: none;
        top: 10px;
        width: 100%;
        bottom: 0;
        height: 100px;
        line-height: 100px;
    }
	/* 雪碧图 */
	.selection-icon {
		display: inline-block;
		position: absolute;
		left: 40px;
		top: 0;
		bottom: 0;
		margin: auto 0;
		height: 40px;
		width: 40px;
	}
	.order-city {
		background: url("https://qn.yiquan.com.cn/web/common/selection-icon2.png") no-repeat 0 0;
		background-size: 140px 40px;
	}
	.order-sp-city {
		background: url("https://qn.yiquan.com.cn/web/common/selection-icon2.png") no-repeat -50px 0;
		background-size: 140px 40px;
	}
	.order-trim {
		background: url("https://qn.yiquan.com.cn/web/common/selection-icon2.png") no-repeat -100px 0;
		background-size: 140px 40px;
	}
</style>