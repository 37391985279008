let baseUrl = '';
// let imgUrl = 'http://bwapi.yiquantuan.cn';
let imgUrl = 'https://bwapi.yiquan.com.cn/';

if(process.env.NODE_ENV == 'development'){
    baseUrl = '/bwapi';
} else if(process.env .NODE_ENV == 'production'){
    // baseUrl = 'http://bwapi.yiquantuan.cn/bwapi';
    baseUrl = 'https://bwapi.yiquan.com.cn/bwapi';
    // baseUrl = '生产地址';
}

export {
    baseUrl,
    imgUrl
}