import Vue from 'vue'
import Router from 'vue-router'

//=====电子书===================================
import booksIndex from '@/pages/books/index.vue'
import booksCarList from '@/pages/books/list.vue'
import booksCarDetail from '@/pages/books/detail.vue'
import carModel from '@/pages/car/model.vue'
import carDetail from '@/pages/car/detail.vue'
import orderSelection from '@/pages/order/selection.vue'
import orderConfirm from '@/pages/order/confirm.vue'
import orderList from '@/pages/order/list.vue'
import orderDetail from '@/pages/order/detail.vue'
//======PC======================================
import pcHome from '@/pages/index/home.vue'

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/home',
            name: 'home',
            component: pcHome,
            children: [
                {path: '*', name: 'home', component: pcHome}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '一圈';
                next();
            }
        },
        //=======电子书========================================
        {
            path: '/books/index',
            name: 'booksIndex',
            component: booksIndex,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '上汽通用产品手册';
                next();
            }
        },
        {
            path: '/books/carlist',
            name: 'booksCarList',
            component: booksCarList,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '上汽通用产品手册';
                next();
            }
        },
        {
            path: '/books/cardetail',
            name: 'booksCarDetail',
            component: booksCarDetail,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '上汽通用产品手册';
                next();
            }
        },
        {
            path: '/model',
            name: 'model',
            component: carModel,
            children: [
                {path : '*', name : 'model'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '车型';
                next();
            }
        },
        {
            path: '/detail',
            name: 'detail',
            component: carDetail,
            children: [
                {path : '*', name : 'detail'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '车辆详情';
                next();
            }
        },
        {
            path: '/selection',
            name: 'selection',
            component: orderSelection,
            children: [
                {path: '*', name: 'brand'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '详情选择';
                next();
            }
        },
        {
            path: '/confirm',
            name: 'confirm',
            component: orderConfirm,
            children: [
                {path: '*', name: 'confirm'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '填写申请资料';
                next();
            }
        },
        {
            path: '/orderList',
            name: 'orderList',
            component: orderList,
            children: [
                {path: '*', name: 'orderList'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '我的订单';
                next();
            }
        },
        {
            path: '/orderDetail',
            name: 'orderDetail',
            component: orderDetail,
            children: [
                {path: '*', name: 'orderDetail'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '订单详情';
                next();
            }
        },
        {path: '*', redirect: '/books/index'}
    ],
    mode: "history",
    base: process.env.VUE_APP_SRC,
})