import Vue from 'vue'
import App from './App.vue'
import '@/plugins/vant'//引入vant组件
import './common/font/pf-m.css'
import './common/font/pf-b.css'
import wechat from './config/wechat'

import router from './router';
import store from './store/index';
import axios from 'axios';
import {post, refreshToken, fetch, uploadFile} from "./config/axios";
// import QQMapWX from '../public/qqmap-wx-jssdk.js'
import '@babel/polyfill';
import Es6Promise from 'es6-promise';
Es6Promise.polyfill();

Vue.prototype.$axios = axios;
Vue.prototype.$wechat = wechat;
// Vue.prototype.$QQMapWX = QQMapWX;

Vue.prototype.$get = fetch;
Vue.prototype.$post = post;
Vue.prototype.$getToken = refreshToken;
Vue.prototype.$uploadFile = uploadFile;

Vue.config.productionTip = false;


// let openId = store.state.user.openid;

router.beforeEach((to, form, next) => {
    //pc识别
    // let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
	// if(!flag){
	// 	//pc端操作（非手机端并且非微信浏览器端）
    //     if(to.path != '/home'){
    //         next({
    //             path: '/home'
    //         });
    //     }else{
    //         next();
    //     }
    //     return false;
    // }else {
	// 	next({
	// 		path: '/books/index'
	// 	});
	// 	return false;
	// }
	// return;
	next();
	return;
});

//后置钩子 用户配置wx配置
router.afterEach((to, from) => {});

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
